
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'UserProfile',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
	},
	methods: {
		getBlogPath: function(user: any){
			return '/blog/'+user.name;
		},
		getDialogPath: function(user: any){
			return '/im/'+user.name;
		},
		getProfileItem: function (field: string){
			switch(field) {
			case "birth_date": return "Дата рождения"
			case "sex": return "Пол"
			case "country": return "Страна"
			case "city": return "Город"
			case "website": return "Сайт"
			case "skype": return "Скайп"
			case "viber": return "Вайбер"
			case "education": return "Образование"
			case "profession": return "Профессия"
			case "interests": return "Интересы"
			case "about": return "Пара слов обо мне"
			case "want_buy": return "Хочу купить"
			case "want_gift": return "Хочу чтобы подарили"
			case "already_buy": return "Уже купил"
			case "already_gift": return "Уже подарили"
			case "already_have": return "Хочу похвастаться что у меня есть"
			case "films": return "Мои любимые фильмы"
			case "actors": return "Мои любимые актёры"
			case "books": return "Мои любимые книги"
			case "writers": return "Мои любимые авторы"
			case "tv_shows": return "Любимые телепередачи"
			case "cars": return "Мои любимые марки автомобилей"
			case "animals": return "Моё любимое животное"
			case "mobiles": return "Мои любимые марки смартфонов"
			case "drinks": return "Мои любимые напитки"
			case "cinemas": return "Мои любимые кинотеатры"
			case "cafes": return "Мои любимые рестораны, кафе, пабы, бары"
			case "clubs": return "Мои любимые клубы"
			case "theaters": return "Мои любимые театры"
			case "halls": return "Мои любимые концертные залы"
			case "shops": return "Мои любимые магазины"
			case "fitness": return "Мои любимые фитнесс-центры, бассейны"
			case "rests": return "Мои любимые места отдыха"
			case "singers": return "Моя любимая группа/исполнитель"
			case "tracks": return "Моя любимая песня/мелодия/альбом"
			case "sports": return "Мой любимый вид спорта"
			case "fan_teams": return "Я болею за"
			case "fan_to": return "Я фанатею от"
			case "free_time": return "В свободное время я"
			case "travels": return "Я люблю путешествовать в"
			case "languages": return "Иностранные языки, которые я знаю"
			case "skills": return "Ещё я умею"
			case "advices": return "И могу помочь в вопросах"
			default:
				return "Неизвестное поле"
			}
		}
	},
  setup() {
    const route = useRoute();
    const folder = ref(route.params.id || 'Профиль');
    const matchedFolder = computed(() => route.params.id);
	const { token, getRichText } = useDataService();
    
    watch(matchedFolder, () => {
      folder.value = matchedFolder.value as string;
    })
	
	const user = ref(null);
	
	axios.get('https://beon.fun/api/v1/users/'+route.params.id,{
	headers: {
		Authorization: 'Bearer ' + token.value,
	}})
    .then(response => (
		user.value = response.data,
		folder.value = response.data.nickname
	));
    return { folder, user, getRichText }
  }
}
